function getOffsetTop(elem) {
    let offsetTop = 0;
    do {
        if (!isNaN(elem.offsetTop)) {
            offsetTop += elem.offsetTop
        }
    } while( elem = elem.offsetParent )
    return offsetTop
}

class ApiPlugin {

    response = null
    error = null

    constructor(url, method, body) {
        if (url.indexOf('://') == -1) {
            url = location.origin + url
        }
        this.url = url
        this.method = method
        this.body = body
    }

    getURL() {
        const url = new URL(this.url)
        if (this.method == 'GET' && this.body) {
            url.search = new URLSearchParams(this.body).toString()
            this.body = null
        }
        return url
    }

    async send() {
        const url = this.getURL()
        const options = {
            method: this.method,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }
        if (this.body) {
            options.body = JSON.stringify(this.body)
        }
        this.response = await fetch(url, options)
        if (!this.response.ok) {
            await this.setError()
        }
    }

    async result(resultType) {
        let result = false
        switch (resultType) {
            case 'json': 
                result = await this.response.json()
                break
            case 'text': 
                result = await this.response.text()
                break
            case 'blob': 
                result = await this.response.blob()
                break
        }
        return result
    }

    async setError() {
        let title = 'Ошибка запроса'
        let message = 'Не удалось выполнить операцию'
        let innerData = {}
        const code = this.response.status
        if (code == 401) { //Направляем на авторизацию
            app.content.isAuth = false
            return router.push('/')
        } else if (code == 404) { //Неверный путь запроса
            message = 'Такого маршрута не существует'
        } else if (code == 400) { //Ошибка запроса
            try {
                const data = await this.result('json')
                if (data) {
                    if (data?.title) title = data.title
                    if (data?.message) message = data.message
                    if (data?.data) innerData = data.data
                }
            } catch(e) {}
        } else if (code >= 500) { //Ошибка на сервере
            title = 'Серверная ошибка'
            message = 'Сервер не может ответить на запрос'
        }
        this.error = { code, title, message, data: innerData }
    }

    hasError() {
        return !!this.error
    }

    static install(Vue, options) {
        Vue.prototype.$api = this.api
    }

    static async api(apiName, body = null, resultType = 'json') {
        let query = null
        try {
            if (!api || !(apiName in api)) return false
            const req = api[apiName]
            query = new ApiPlugin(req.url, req.type, body)
            await query.send()
            if (!query.hasError()) {
                const data = await query.result(resultType)
                // TODO: Может будут доп. действия в ответе сервера ?
                return { result: true, data }
            }
        } catch(e) {
            console.error('API request failed', e)
        }
        if (query.error) {
            this.$root.message(query.error.title, query.error.message, 3)
        }
        return { result: false, error: query.error }
    }

}

Vue.use(ApiPlugin)