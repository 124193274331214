//Данные из страницы
let content = {}
try {
    content = JSON.parse(document.getElementById('content').textContent);
} catch(e) {}

//Страницы
const Login = async () => {
    return import('./pages/Login.js')
}

const Projects = async () => {
    return import('./pages/Projects.js')
}

const Subscribe = async () => {
    return import('./pages/Subscribe.js')
}

const NotFound = async () => {
    return import('./pages/NotFound.js')
}

//Мидлвар проверки авторизации
function middleware(to, from, next) {
    if (to.name == 'Login') {
        if (content.isAuth) return next('/projects')
    } else {
        if (to.meta.auth && !content.isAuth) {
            return next({
                name: 'Login',
                query: {
                    from: to.name
                }
            })
        }
    }
    next()
}

//Роуты для страниц
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {title: 'Вход'},
        beforeEnter: middleware
    },
    {
        path: '/projects',
        name: 'Projects',
        meta: { auth: true, title: 'Проекты' },
        component: Projects,
        beforeEnter: middleware
    },
    {
        path: '/my-projects',
        name: 'My',
        meta: { auth: true, title: 'Мои проекты' },
        component: Projects,
        beforeEnter: middleware
    },
    {
        path: '/finished-projects',
        name: 'Archive',
        meta: { auth: true, title: 'Мои завершенные проекты' },
        component: Projects,
        beforeEnter: middleware
    },
    {
        path: '/subscribe',
        name: 'Subscribe',
        meta: { auth: true, title: 'Управление рассылкой' },
        component: Subscribe,
        beforeEnter: middleware
    },
    {
        path: '*', component: NotFound
    }
]

const router = new VueRouter({routes, mode: 'history'})

router.beforeEach((to, from, next) => {
    try {
        if (app) document.body.style.overflow = 'hidden'
    } catch(e) {}
    next()
})

router.afterEach((to, from) => {
    setTimeout(() => document.body.style.overflow = '', 610)
    Vue.nextTick(() => {
        document.title = to.meta.title || 'ПСК Гончаренко';
    });
})

// Если ошибка,
//      то 4** статус-код с {title:'Error',message:'Error text'},
//      иначе 2** c {...}
const api = {
    //Авторизация
    login:      { type: 'POST',   url: '/api/login/' },
    //Выйти из профиля
    logout:     { type: 'POST',   url: '/api/logout/' },
    //Восстановление пароля
    recovery:   { type: 'POST',   url: '/api/recovery/' },
    //Управление подпиской
    subscribe:   { type: 'POST',   url: '/api/subscribe/' },
    //Получение данных каталога (списки, фильтры, пагинация, проекты)
    catalog:    { type: 'GET',    url: '/api/catalog/' },
    //Получение данных проекта
    project:    { type: 'GET',    url: '/api/project/' },
    //Отклик на проект
    participate:{ type: 'POST',   url: '/api/project/participate/' },
    //Ответы на часто задаваемые вопросы
    faq:        { type: 'GET',    url: '/api/faq/' },
}

const app = new Vue({
    router,
    el: '#app',
    data: {
        content,
        mobMaxSize: 1020,
        actualFilter: {},
        statuses: {},
        modal: {}, openedModal: null,
        shadow: null,
        mobMenu: null,
        reverts: new Set(),
        projectsComp: null,
        faq: null
    },
    methods: {
        setPageProjects(comp) {
            this.projectsComp = comp
        },
        clearReverts() {
            this.reverts.clear()
        },
        revert() {
            this.reverts.forEach(fn => {
                try {
                    fn()
                } catch (e) {console.error(e)}
            })
        },
        isMobile() {
            return window.innerWidth <= this.mobMaxSize
        },
        showModal(name, params) {
            if (!this.modal[name]) return
            if (this.openedModal) {
                const oldName = this.openedModal.name
                this.closeModal()
                if (oldName == name) return
                setTimeout(() => this._showModal(name, params), 50)
            } else this._showModal(name, params)
        },
        closeModal() {
            if (this.openedModal) {
                this.openedModal.close()
                this.openedModal = null
            }
            this.mobMenu?.active()
        },
        setMobMenu(comp = null) {
            this.mobMenu = comp
        },
        _showModal(name, params) {
            this.openedModal = this.modal[name]
            this.modal[name].open(() => {
                this.openedModal = null
            }, params)
            this.mobMenu?.active(name)
        },
        getBottomOffset() {
            if (!this.isMobile()) return 0
            const foot = document.querySelector('.mob-foot')
            if (foot) return foot.clientHeight
            return 0
        },
        canProjectAction(status) {
            let current
            if (status in this.statuses) current = this.statuses[status]
            if (!current) return false
            return current.canAction || false
        },
        hasMessengers() {
            const m = this.content.company?.messengers
            if (!m) return false
            return  m.whatsapp ||
                    m.telegram ||
                    m.viber
        },
        message(title, message, type) {
            this.$refs.toast.show(title, message, type)
        },
        setStatuses(statuses) {
            if (!(statuses instanceof Array)) return
            this.statuses = statuses.reduce((res, status) => {
                res[status.id] = status
                return res
            }, {})
        },
        getStatusID(name) {
            for (let id in this.statuses) {
                if (this.statuses[id].name === name) return id
            }
            return false
        },
        addFilter(prop, items) {
            if (!(prop in this.actualFilter)) {
                this.$set(this.actualFilter, prop, [])
            }
            this.actualFilter[prop].push(...items)
        },
        removeFilter(prop, items) {
            items.forEach(item => {
                const pos = this.hasFilter(prop, item)
                if (pos == -1) return
                this.actualFilter[prop].splice(pos, 1)
            })
        },
        hasFilter(prop, item) {
            if (!(prop in this.actualFilter)) return -1
            return this.actualFilter[prop].indexOf(item)
        },
        getFilter() {
            const params = {}
            for (let prop in this.actualFilter) {
                const ids = this.actualFilter[prop].map(item => item.id)
                if (!ids.length) continue
                params[prop] = ids
            }
            return params
        },
        clearFilter(withUpdate = false) {
            for (let prop in this.actualFilter) {
                const items = this.actualFilter[prop]
                items.forEach(item => item.checked = false)
                items.splice(0, items.length)
            }
            if (withUpdate) this.updateProjects()
        },
        updateProjects() {
            this.projectsComp?.clearPagination()
            this.projectsComp?.updatePage()
        },
        updateSubscribe(status) {
            this.content.user.subscribed = status
        }
    },
    mounted() {
        this.shadow = this.$refs.shadow
    }
})